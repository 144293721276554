<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameStatusFull from "../../../components/common/questionnaires/fieldsets/full/NameStatusFull";
import ChildrenDependantsFull from "../../../components/common/questionnaires/fieldsets/full/ChildrenDependantsFull";
import AssetsFull from "../../../components/common/questionnaires/fieldsets/full/AssetsFull";
import LiabilitiesFull from "../../../components/common/questionnaires/fieldsets/full/LiabilitiesFull";
import AddressFull from "../../../components/common/questionnaires/fieldsets/full/AddressFull";
import Executors from "../../../components/common/questionnaires/fieldsets/will/Executors";
import Trustees from "../../../components/common/questionnaires/fieldsets/will/Trustees";
import Guardians from "../../../components/common/questionnaires/fieldsets/will/Guardians";
import Pets from "../../../components/common/questionnaires/fieldsets/will/Pets";
import Funeral from "../../../components/common/questionnaires/fieldsets/will/Funeral";
import Gifts from "../../../components/common/questionnaires/fieldsets/will/Gifts";
import DistributionPrimary from "@/components/common/questionnaires/fieldsets/will/DistributionPrimary";
import DistributionSecondary from "@/components/common/questionnaires/fieldsets/will/DistributionSecondary";
import DistributionBackup from "@/components/common/questionnaires/fieldsets/will/DistributionBackup";

export default {
  name: "WillFull",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    NameStatusFull,
    ChildrenDependantsFull,
    AssetsFull,
    LiabilitiesFull,
    AddressFull,
    Executors,
    Trustees,
    Guardians,
    Pets,
    Funeral,
    Gifts,
    DistributionPrimary,
    DistributionSecondary,
    DistributionBackup
  },
  data() {
    return {
      name: 'WillFull',
      title: "Will Instructions Full Demo",
      url: 'will_full',
      order:
          [
            'NameStatusFull',
            'AddressFull',
            'ChildrenDependantsFull',
            'AssetsFull',
            'LiabilitiesFull',
            'Executors',
            'Trustees',
            'Guardians',
            'Gifts',
            'DistributionPrimary',
            'DistributionSecondary',
            'DistributionBackup',
            'Pets',
            'Funeral'
          ],
      expiryAccessCodeOnFinish: true


    }
  }
};
</script>
